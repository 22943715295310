<template>
  <div class="container">
    <div class="banner"></div>
    <div
      class="partOne"
      :class="{ partOneActive: partOneActive }"
      @click="
        showVideo(
          '飞依诺vinnoG86产品影片',
          'https://kangfei123.oss-cn-beijing.aliyuncs.com/1%E9%A3%9E%E4%BE%9D%E8%AF%BAG86%E4%BA%A7%E5%93%81%E5%BD%B1%E7%89%87.mp4'
        )
      "
    >
      <div class="gray"></div>
      <div class="imgOne"><img :src="`img/partOne.png`" alt="" /></div>

      <div class="txt1">
        COMMERCIAL WORKS
        <span>商业项目</span>
      </div>
      <div class="txt2">
        <div class="pro">
          <span>飞依诺vinnoG86产品影片</span>
          <div class="line"></div>
        </div>
        <div class="more"><i class="el-icon-right"></i> MROE</div>
      </div>
    </div>
    <div
      class="partTwo"
      :class="{ partTwoActive: partTwoActive }"
      @click="
        showVideo(
          'Into Care 电动吻合器',
          'https://kangfei123.oss-cn-beijing.aliyuncs.com/6%E8%8B%B1%E9%80%94%E5%BA%B7ICS%E4%B8%89%E7%BB%B4%E5%BD%B1%E7%89%87.mp4'
        )
      "
    >
      <div class="gray"></div>
      <div class="imgTwo"><img :src="`img/partTwo.png`" alt="" /></div>

      <div class="txt1">
        <span>商业项目</span>
        COMMERCIAL WORKS
      </div>
      <div class="txt2">
        <div class="pro">
          <span>Into Care 电动吻合器</span>
          <div class="line"></div>
        </div>
        <div class="pro">
          <span>产品视频项⽬</span>
          <div class="line" style="width: 240px"></div>
        </div>
        <div class="more"><i class="el-icon-right"></i> MROE</div>
      </div>
    </div>
    <div class="service" :class="{ partThreeActive: partThreeActive }">
      <div class="serverTitle">
        <span>服务板块</span>
        <div class="line"></div>
      </div>
      <div class="info">
        致力于高品质视频制作，为客户提供品牌形象视频，产品功能展示视频、病毒视频、微电影、企业专题视频、<br />
        科技产品及医疗产品3D特效视频等制作服务。
      </div>
      <div class="project">
        <div class="left">
          <div class="service5">
            <img :src="`img/service5.png`" alt="" />
          </div>
          <div class="service2">
            <router-link :to="{ path: '/project', query: { cur: 2 } }">
              <img :src="`img/service2.png`" alt="" />
              <div class="txt">
                <div class="big">企业宣传片</div>
                <div class="small">Corporate Promotional video</div>
              </div>
              <div class="he_border1_caption">
                <a class="he_border1_caption_a" href="#"></a>
              </div>
            </router-link>
          </div>
          <div class="service3">
            <router-link :to="{ path: '/project', query: { cur: 3 } }">
              <img :src="`img/service3.png`" alt="" />
              <div class="txt">
                <div class="big">产品宣传片</div>
                <div class="small">Product promotion video</div>
              </div>
              <div class="he_border1_caption">
                <a class="he_border1_caption_a" href="#"></a>
              </div>
            </router-link>
          </div>
        </div>
        <div class="right">
          <div class="service4">
            <router-link :to="{ path: '/project', query: { cur: 5 } }">
              <img :src="`img/service4.png`" alt="" />
              <div class="txt t4">
                <div class="big">MG动画</div>
                <div class="small">MG animation</div>
              </div>
              <div class="he_border1_caption">
                <a class="he_border1_caption_a" href="#"></a>
              </div>
            </router-link>
          </div>
          <div class="service1">
            <router-link :to="{ path: '/project', query: { cur: 4 } }">
              <img :src="`img/service1.png`" alt="" />
              <div class="txt t5">
                <div class="big">三维视频</div>
                <div class="small">3 d video</div>
              </div>
              <div class="he_border1_caption">
                <a class="he_border1_caption_a" href="#"></a>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="partner" :class="{ partFourActive: partFourActive }">
      <div class="smallTitle">
        <div class="tit">合作伙伴</div>
        <div class="info">
          康斐互动为医疗行业客户提供高品质的视频制作，为客户提供一站式的视频整体解决方案。
        </div>
      </div>
      <div class="partnerPic">
        <div class="partList">
          <div class="pImg one"></div>
          <div class="pImg two"></div>
          <div class="pImg three"></div>
          <div class="pImg four"></div>
          <div class="pImg five"></div>
          <div class="pImg six"></div>
          <div class="pImg seven"></div>
          <div class="pImg eight"></div>
        </div>
      </div>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="80%"
      center
      :before-close="closeVideo"
    >
      <video
        ref="myAudio"
        class="showVideo"
        controls
        autoplay
        :src="mySrc"
      ></video>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeVideo">取 消</el-button>
        <el-button type="primary" @click="closeVideo">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: [
    "partOneActive",
    "partTwoActive",
    "partThreeActive",
    "partFourActive",
  ],
  data() {
    return {
      dialogVisible: false,
      title: "",
      mySrc: "",
    };
  },
  methods: {
    showVideo(title, src) {
      this.title = title;
      this.audioPause();
      this.dialogVisible = true;
      this.mySrc = src;
    },
    closeVideo() {
      this.audioPause();
      this.dialogVisible = false;
    },
    audioPause() {
      //暂停并且设置当前播放时间为0
      let audio = this.$refs.myAudio;
      if (!!audio && (!audio.paused || audio.currentTime !== 0)) {
        audio.pause();
        audio.currentTime = 0;
      }
    },
  },
};
</script>

<style lang="stylus" scoped  rel="stylesheet/stylus">
.container {
  height: 4228px;
  background: url('../../public/img/bg.png') no-repeat center top;
  background-size: contain;
  overflow: hidden;

  .banner {
    width: 100%;
    height: 690px;
    background: url('../../public/img/banner.png') no-repeat center top;
    background-size: auto 690px;
  }

  .partOne {
    width: 1240px;
    margin: 0 auto;
    position: relative;
    padding-top: 100px;
    height: 567px;
    overflow: hidden;

    .gray {
      width: 765px;
      height: 452px;
      background: #FAFAFA;
      margin-left: 364px;
      margin-top: 15px;
    }

    .imgOne {
      position: absolute;
      top: 184px;
      left: 492px;
      width: 760px;
      height: 447px;
      cursor: pointer;

      img {
        width: 100%;
      }
    }

    .txt1 {
      position: absolute;
      top: 100px;
      left: 0;
      font-size: 34px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #2A2A2A;
      opacity: 0;
      -ms-transform: translate(0, 30px);
      transform: translate(0, 30px);
      transition: all 1s 0.05s;

      span {
        display: block;
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: 300;
        color: #292929;
        padding-top: 10px;
      }
    }

    .txt2 {
      position: absolute;
      top: 260px;
      left: 0;
      opacity: 0;
      -ms-transform: translate(0, 30px);
      transform: translate(0, 30px);
      transition: all 1s 0.6s;

      .pro {
        position: relative;

        span {
          position: relative;
          z-index: 3;
          font-size: 40px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #292929;
        }

        .line {
          position: absolute;
          top: 30px;
          left: 10px;
          width: 487px;
          height: 15px;
          background: #57A633;
        }
      }

      .more {
        padding-top: 30px;
        height: 12px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #292929;
        cursor: pointer;
      }
    }

    &.partOneActive {
      .txt1 {
        opacity: 1;
        -ms-transform: translate(0, 0);
        transform: translate(0, 0);
      }

      .txt2 {
        opacity: 1;
        -ms-transform: translate(0, 0);
        transform: translate(0, 0);
      }
    }
  }

  .partTwo {
    width: 1240px;
    margin: 90px auto 234px;
    position: relative;
    height: 460px;
    padding-bottom: 130px;
    overflow: hidden;

    .gray {
      width: 777px;
      height: 459px;
      background: #FAFAFA;
      margin-left: 198px;
    }

    .imgTwo {
      position: absolute;
      top: 130px;
      left: 0;
      width: 763px;
      height: 431px;
      cursor: pointer;

      img {
        width: 100%;
      }
    }

    .txt1 {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 34px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #2A2A2A;
      opacity: 0;
      -ms-transform: translate(0, 30px);
      transform: translate(0, 30px);
      transition: all 1s 0.05s;

      span {
        display: block;
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: 300;
        color: #292929;
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }

    .txt2 {
      position: absolute;
      top: 230px;
      right: 0;
      opacity: 0;
      -ms-transform: translate(0, 30px);
      transform: translate(0, 30px);
      transition: all 1s 0.6s;

      .pro {
        position: relative;
        margin-bottom: 10px;

        span {
          position: relative;
          z-index: 3;
          font-size: 40px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #292929;
        }

        .line {
          position: absolute;
          top: 30px;
          left: 0;
          width: 487px;
          height: 15px;
          background: #57A633;
        }
      }

      .more {
        padding-top: 30px;
        height: 12px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #292929;
        cursor: pointer;
      }
    }

    &.partTwoActive {
      .txt1 {
        opacity: 1;
        -ms-transform: translate(0, 0);
        transform: translate(0, 0);
      }

      .txt2 {
        opacity: 1;
        -ms-transform: translate(0, 0);
        transform: translate(0, 0);
      }
    }
  }

  .service {
    width: 1240px;
    margin: 0 auto;
    position: relative;

    .serverTitle {
      position: relative;
      width: 242px;
      text-align: center;
      margin: 0 auto 20px;
      opacity: 0;
      -ms-transform: translate(0, 30px);
      transform: translate(0, 30px);
      transition: all 1s 0.05s;

      span {
        position: relative;
        z-index: 3;
        font-size: 48px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #292929;
      }

      .line {
        position: absolute;
        top: 30px;
        left: 0;
        width: 242px;
        height: 15px;
        background: #57A633;
      }
    }

    .info {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 300;
      color: #000000;
      line-height: 38px;
      text-align: center;
      padding: 0 28px;
      opacity: 0;
      -ms-transform: translate(0, 30px);
      transform: translate(0, 30px);
      transition: all 1s 0.6s;
    }

    .project {
      display: flex;
      justify-content: space-between;
      margin-bottom: 170px;

      .txt {
        position: absolute;
        right: 0;
        bottom: 0;
        background-color: #ffffff;
        width: 260px;
        height: 57px;
        padding: 10px 0 0 15px;
        box-sizing: border-box;
        z-index: 9;

        .big {
          font-size: 27px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #3D3D3D;
        }

        .small {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #3D3D3D;
        }

        &.t4 {
          width: 226px;
        }

        &.t5 {
          width: 157px;
        }
      }

      .left {
        width: 575px;

        img {
          width: 100%;
        }

        .service5 {
          margin-bottom: 50px;
          position: relative;
          overflow: hidden;

          img {
            width: 560px;
          }
        }

        .service3 {
          position: relative;
          overflow: hidden;

          &:hover {
            img {
              transform: scale(1.8);
              opacity: 0.9;
            }

            .he_border1_caption::before, .he_border1_caption::after {
              opacity: 1;
              -webkit-transform: scale(1);
              -moz-transform: scale(1);
              -o-transform: scale(1);
              transform: scale(1);
            }
          }
        }

        .service2 {
          margin-bottom: 76px;
          position: relative;
          overflow: hidden;

          &:hover {
            img {
              transform: scale(1.8);
              opacity: 0.9;
            }

            .he_border1_caption::before, .he_border1_caption::after {
              opacity: 1;
              -webkit-transform: scale(1);
              -moz-transform: scale(1);
              -o-transform: scale(1);
              transform: scale(1);
            }
          }
        }
      }

      .right {
        width: 580px;

        img {
          width: 100%;
        }

        .service4 {
          margin-top: 135px;
          position: relative;
          overflow: hidden;

          &:hover {
            img {
              transform: scale(1.8);
              opacity: 0.9;
            }

            .he_border1_caption::before, .he_border1_caption::after {
              opacity: 1;
              -webkit-transform: scale(1);
              -moz-transform: scale(1);
              -o-transform: scale(1);
              transform: scale(1);
            }
          }
        }

        .service1 {
          margin-top: 93px;
          position: relative;
          overflow: hidden;

          &:hover {
            img {
              transform: scale(1.8);
              opacity: 0.9;
            }

            .he_border1_caption::before, .he_border1_caption::after {
              opacity: 1;
              -webkit-transform: scale(1);
              -moz-transform: scale(1);
              -o-transform: scale(1);
              transform: scale(1);
            }
          }
        }
      }

      .he_border1_caption {
        color: #fff;
        padding: 0;
        margin: 0;
        -moz-backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        transition: all 0.4s ease-in-out;
        overflow: hidden;
      }

      .he_border1_caption::before, .he_border1_caption::after {
        position: absolute;
        content: '';
        opacity: 0;
        -webkit-transition: opacity 0.4s, -webkit-transform 0.4s;
        -moz-transition: opacity 0.4s, -moz-transform 0.4s;
        -o-transition: opacity 0.4s, -o-transform 0.4s;
        transition: opacity 0.4s, transform 0.4s;
      }

      .he_border1_caption::before {
        top: 8%;
        right: 10%;
        bottom: 8%;
        left: 10%;
        border-top: 2px solid #ffffff;
        border-bottom: 2px solid #ffffff;
        -webkit-transform: scale(0, 1);
        -moz-transform: scale(0, 1);
        -o-transform: scale(0, 1);
        transform: scale(0, 1);
        -webkit-transform-origin: 0 0;
        -moz-transform-origin: 0 0;
        -o-transform-origin: 0 0;
        transform-origin: 0 0;
      }

      .he_border1_caption::after {
        top: 8%;
        right: 10%;
        bottom: 8%;
        left: 10%;
        border-right: 2px solid #ffffff;
        border-left: 2px solid #ffffff;
        -webkit-transform: scale(1, 0);
        -moz-transform: scale(1, 0);
        -o-transform: scale(1, 0);
        transform: scale(1, 0);
        -webkit-transform-origin: 100% 0;
        -moz-transform-origin: 100% 0;
        -o-transform-origin: 100% 0;
        transform-origin: 100% 0;
      }

      .he_border1_caption, .he_border1_caption > a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        -webkit-transition: all 0.4s ease-in-out;
        -moz-transition: all 0.4s ease-in-out;
        -o-transition: all 0.4s ease-in-out;
        -ms-transition: all 0.4s ease-in-out;
        transition: all 0.4s ease-in-out;
        overflow: hidden;
        z-index: 1;
      }
    }

    &.partThreeActive {
      .serverTitle {
        opacity: 1;
        -ms-transform: translate(0, 0);
        transform: translate(0, 0);
      }

      .info {
        opacity: 1;
        -ms-transform: translate(0, 0);
        transform: translate(0, 0);
      }
    }
  }

  .partner {
    position: relative;

    .smallTitle {
      width: 1240px;
      margin: 0 auto 50px;
      text-align: center;

      .tit {
        text-align: center;
        font-size: 46px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #292929;
        opacity: 0;
        -ms-transform: translate(0, 30px);
        transform: translate(0, 30px);
        transition: all 1s 0.05s;
      }

      .info {
        font-size: 25px;
        font-family: Source Han Sans CN;
        font-weight: 300;
        color: #000000;
        line-height: 38px;
        padding-top: 15px;
        opacity: 0;
        -ms-transform: translate(0, 30px);
        transform: translate(0, 30px);
        transition: all 1s 0.6s;
      }
    }

    .partnerPic {
      width: 100%;
      height: 490px;
      background: url('../../public/img/partner.png') no-repeat center top;
      background-size: auto 490px;
      padding-top: 45px;

      .partList {
        display: flex;
        flex-wrap: wrap;
        width: 1230px;
        height: 400px;
        margin: 0 auto;
        background: url('../../public/img/pbj.png') no-repeat center top;
        background-size: 1230px 400px;

        .pImg {
          width: 307px;
          height: 200px;

          &.one {
            background: url('../../public/img/partner1One.png') no-repeat center center;
            background-size: 161px 96px;
          }

          &.one:hover {
            background: url('../../public/img/partner1Two.png') no-repeat center center;
          }

          &.two {
            background: url('../../public/img/partner2One.png') no-repeat center center;
            background-size: 230px 81px;
          }

          &.two:hover {
            background: url('../../public/img/partner2Two.png') no-repeat center center;
          }

          &.three {
            background: url('../../public/img/partner3One.png') no-repeat center center;
            background-size: 261px 97px;
          }

          &.three:hover {
            background: url('../../public/img/partner3Two.png') no-repeat center center;
          }

          &.four {
            background: url('../../public/img/partner4One.png') no-repeat center center;
            background-size: 209px 103px;
          }

          &.four:hover {
            background: url('../../public/img/partner4Two.png') no-repeat center center;
          }

          &.five {
            background: url('../../public/img/partner5One.png') no-repeat center center;
            background-size: 232px 56px;
          }

          &.five:hover {
            background: url('../../public/img/partner5Two.png') no-repeat center center;
          }

          &.six {
            background: url('../../public/img/partner6One.png') no-repeat center center;
            background-size: 218px 50px;
          }

          &.six:hover {
            background: url('../../public/img/partner6Two.png') no-repeat center center;
          }

          &.seven {
            background: url('../../public/img/partner7One.png') no-repeat center center;
            background-size: 227px 81px;
          }

          &.seven:hover {
            background: url('../../public/img/partner7Two.png') no-repeat center center;
          }

          &.eight {
            background: url('../../public/img/partner8One.png') no-repeat center center;
            background-size: 253px 61px;
          }

          &.eight:hover {
            background: url('../../public/img/partner8Two.png') no-repeat center center;
          }
        }
      }
    }

    &.partFourActive {
      .tit {
        opacity: 1;
        -ms-transform: translate(0, 0);
        transform: translate(0, 0);
      }

      .info {
        opacity: 1;
        -ms-transform: translate(0, 0);
        transform: translate(0, 0);
      }
    }
  }

  .showVideo {
    width: 100%;
  }
}
</style>